/* ==========================================================================
   CLEARFIX
   ========================================================================== */

/**
 * Attach our clearfix mixin to a utility class.
 */

.u-clearfix {
    @include clearfix();
}
