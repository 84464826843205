//$module-name: 'shows';
//
//@include settings((
//));

.shows {
    width: 100%;
    padding: 0 32px;
    list-style: none;
    margin: 90px 0;
    @include mq($from: tablet) {
        margin: 90px 0 170px;
    }
}

.shows-entry {
    padding: 0;
    list-style: none;
    margin: 30px 0;
}

.shows-link {
    display: block;
    border-bottom: none;
    @include font-medium;
    text-align: center;

    &:hover, &:focus, &:visited {
        border: none;
    }
}

.shows-venue {
    @include font-huge;
    display: block;
    @include mq($from: phablet) {
        display: inline-block;
    }
}
