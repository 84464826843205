/* ==========================================================================
   GENERAL
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 *
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 *
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
    font-size: ($global-font-size / 16px) * 1em; /* [1] */
    line-height: $global-line-height / $global-font-size; /* [1] */
    overflow-y: scroll; /* [2] */
    overflow-x: hidden;
    height: 100%;
    min-height: 100%; /* [3] */
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    background-color: $c-background;
    color: $c-text;
    font-family: $font-text;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

