/* ========================================================================
   TYPO
   ======================================================================== */

// Base typographical styles and baseline grid. You need to define these values
// in pixels: we will convert them to more appropriate units.
$global-font-size: 16px;
$global-line-height: 24px;


// Font Families
$font-title: GrotexBETA-Medium, Helvetica, Arial, sans-serif;
$font-text: GrotexBETA-Medium, Helvetica, Arial, sans-serif;


// Example Font definitions

@mixin font-huge() {
    @include fluid-type(phablet, desktop, 35px, 80px, 1);
    font-weight: normal;
    letter-spacing: 0;
}

@mixin font-large() {
    @include fluid-type(phablet, desktop, 20px, 25px, 1.8);
    font-weight: normal;
    letter-spacing: 0.05em;
}

@mixin font-medium() {
    @include fluid-type(phablet, desktop, 15px, 25px, 1);
    font-weight: normal;
    letter-spacing: 0.05em;
}

@mixin font-small() {
    @include font-size(15px, 1);
    font-weight: normal;
    letter-spacing: 0.03em;
}
