/* ========================================================================
   FONT SIZE
   ======================================================================== */

/**
 * Generate a font-size and baseline-compatible line-height.
 */

@mixin font-size($font-size, $line-height: auto, $important: false, $fluid-type-min-font-size: false) {

    @if ($important == true) {
        $important: !important;
    } @else if ($important == false) {
        $important: null;
    } @else {
        @error "`#{$important}` needs to be `true` or `false`."
    }

    font-size: $font-size $important;
    @if ($fluid-type-min-font-size == false) {
        font-size: ($font-size / $global-font-size) * 1rem $important;
    }

    @if ($line-height == 'auto') {
        @if ($fluid-type-min-font-size != false) {
            $font-size: $fluid-type-min-font-size;
        }
        line-height: ceil($font-size / $global-line-height) * ($global-line-height / $font-size) $important;
    }

    @else {

        @if (type-of($line-height) == number or $line-height == 'inherit' or $line-height == 'normal') {
            line-height: $line-height $important;
        }

        @else if ($line-height != 'none' and $line-height != false) {
            @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`."
        }

    }

}
