a, a:hover, a:focus, a:visited {
    color: $c-text;
    text-decoration: none;
    border-bottom: 1px solid rgba($c-text, 0.33);
    transition: border 0.2s ease;
}

a:hover {
    border-bottom: 1px solid rgba($c-text, 1);
}
