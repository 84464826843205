/* ==========================================================================
   HEADINGS
   ========================================================================== */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1:
 *
 *   <h2 class="u-h1"></h2>
 *
 */

.u-h1 {
    // Use mixins
    // @include font-title();
}

.u-h2 {

}

.u-h3 {

}

.u-h4 {

}

.u-h5 {

}

.u-h6 {

}
