//$module-name: 'mountain';
//
//@include settings((
//));

.mountain {
    @include fluid-type(phablet, desktop, 65px, 88px, 1);
    margin-top: 1.25em;
    width: 100%;
}

.mountain img {
    max-width: 1142px;
    width: 100%;
    margin: 0 auto;
    display: block;

    @include mq($until: phablet) {
        width: calc(100% + 2 * 76px);
        margin: 0 -76px;
    }
}
