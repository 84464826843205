html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

body, h1, h2, h3, h4, h5, h6, blockquote, p, pre, dl, dd, ol, ul, figure, hr, fieldset, legend {
  margin: 0;
  padding: 0;
}

li > ol, li > ul {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  min-width: 0;
  border: 0;
}

html {
  height: 100%;
  min-height: 100%;
  font-size: 1em;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .03em;
  background-color: #212121;
  font-family: GrotexBETA-Medium, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

@font-face {
  font-family: GrotexBETA-Medium;
  src: url("GrotexBETA-Medium.55949d2c.woff2") format("woff2"), url("GrotexBETA-Medium.b257c796.woff") format("woff");
}

@media print {
  *, :before, :after, :first-letter, :first-line {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

[v-cloak] {
  visibility: hidden;
}

img {
  max-width: 100%;
  vertical-align: middle;
  font-style: italic;
}

img[width], img[height] {
  max-width: none;
}

a, a:hover, a:focus, a:visited {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, .33);
  text-decoration: none;
  transition: border .2s;
}

a:hover {
  border-bottom: 1px solid #fff;
}

table, .album {
  width: 100%;
}

.album-cover {
  max-width: 640px;
  width: 70%;
  background-color: #000;
  border: none;
  margin: 0 auto 2.4em;
  display: block;
  position: relative;
}

.album-cover:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.album-cover:hover, .album-cover:focus, .album-cover:visited {
  border: none;
}

.album-cover img {
  width: 100%;
  opacity: 0;
  display: block;
  position: absolute;
}

.album-cover img.lazyloaded {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.album-links {
  width: 100%;
  letter-spacing: .03em;
  margin: 2.8em 0 0;
  padding: 0;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1;
  list-style: none;
}

.album-link {
  text-transform: none;
  text-align: center;
  margin: 0 auto 1.1em;
  padding: 0;
  list-style: none;
}

.footerLinks {
  width: 100%;
  letter-spacing: .03em;
  padding: 4em 32px 0;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1.4;
  display: block;
}

.footerLinks-entry {
  text-align: center;
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
}

.footerLinks-link {
  text-transform: none;
  margin-left: 20px;
}

@media (max-width: 37.49em) {
  .footerLinks-link {
    margin-left: 0;
  }

  .footerLinks-link:before {
    content: " ";
    display: block;
  }
}

.logo {
  width: 100%;
  color: #000;
  margin: 1.5em 0 2em;
  font-size: 3.5rem;
  line-height: 1;
  position: relative;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .logo {
    font-size: calc(3.5rem + 2.8vw - 16.8px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .logo {
    font-size: 4.375rem;
    line-height: 1;
  }
}

.logo:after {
  content: "";
  padding-bottom: 30%;
  display: block;
}

.logo img {
  max-width: 945px;
  width: 80%;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mountain {
  width: 100%;
  margin-top: 1.25em;
  font-size: 4.0625rem;
  line-height: 1;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .mountain {
    font-size: calc(4.0625rem + 4.6vw - 27.6px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .mountain {
    font-size: 5.5rem;
    line-height: 1;
  }
}

.mountain img {
  max-width: 1142px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

@media (max-width: 37.49em) {
  .mountain img {
    width: calc(100% + 152px);
    margin: 0 -76px;
  }
}

.shows {
  width: 100%;
  margin: 90px 0;
  padding: 0 32px;
  list-style: none;
}

@media (min-width: 56.25em) {
  .shows {
    margin: 90px 0 170px;
  }
}

.shows-entry {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}

.shows-link {
  letter-spacing: .05em;
  text-align: center;
  border-bottom: none;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1;
  display: block;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .shows-link {
    font-size: calc(.9375rem + 2vw - 12px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .shows-link {
    font-size: 1.5625rem;
    line-height: 1;
  }
}

.shows-link:hover, .shows-link:focus, .shows-link:visited {
  border: none;
}

.shows-venue {
  letter-spacing: 0;
  font-size: 2.1875rem;
  font-weight: normal;
  line-height: 1;
  display: block;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .shows-venue {
    font-size: calc(2.1875rem + 9vw - 54px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .shows-venue {
    font-size: 5rem;
    line-height: 1;
  }
}

@media (min-width: 37.5em) {
  .shows-venue {
    display: inline-block;
  }
}

.socialLinks {
  max-width: 310px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 32px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 56.25em) {
  .socialLinks {
    max-width: 682px;
  }
}

.socialLinks li {
  width: 308px;
  border: 1px solid #fff;
  border-bottom: none;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: block;
}

.socialLinks li:last-of-type {
  border: 1px solid #fff;
}

@media (min-width: 56.25em) {
  .socialLinks li:nth-last-of-type(2) {
    border: 1px solid #fff;
  }

  .socialLinks li:nth-of-type(2n) {
    border-left: none;
  }
}

.socialLinks-link {
  letter-spacing: .03em;
  text-align: center;
  width: 100%;
  border-bottom: none;
  padding: 17px 0;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1;
  display: block;
}

.socialLinks-link:hover {
  border-bottom: none;
}

.externalLogos {
  width: 100%;
  text-align: center;
  margin: 1em 0;
  padding: 0;
  font-size: 1.5625rem;
  line-height: 1;
  list-style: none;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .externalLogos {
    font-size: calc(1.5625rem + 5.4vw - 32.4px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .externalLogos {
    font-size: 3.25rem;
    line-height: 1;
  }
}

.externalLogos-entry {
  width: 1em;
  height: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 .6em;
  font-size: 1.125rem;
  line-height: 1;
  display: inline-block;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .externalLogos-entry {
    font-size: calc(1.125rem + 1.6vw - 9.6px);
    line-height: 1;
  }
}

@media (min-width: 68.75em) {
  .externalLogos-entry {
    font-size: 1.625rem;
    line-height: 1;
  }
}

.externalLogos-entry--instagram {
  background-image: url("instagram.b5ef72a2.svg");
  transform: scale(.76);
}

.externalLogos-entry--facebook {
  background-image: url("facebook.8bf696ab.svg");
  transform: scale(.76);
}

.externalLogos-entry--youtube {
  background-image: url("youtube.ccc84f05.svg");
  transform: scale(.92);
}

.externalLogos-entry--soundcloud {
  background-image: url("soundcloud.edb1d4e1.svg");
}

.externalLogos-entry--spotify {
  background-image: url("spotify.3fff9d54.svg");
  transform: scale(.84);
}

.externalLogos-entry--applemusic {
  background-image: url("applemusic.207c0d38.svg");
  transform: scale(.84);
}

.externalLogos-link {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

.externalLogos-link:hover, .externalLogos-link:focus, .externalLogos-link:visited {
  border: none;
}

.spotifyLinks {
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  display: block;
}

.spotifyLinks-item {
  padding-bottom: 32px;
}

.spotifyLinks-item:last-of-type {
  padding-bottom: 0;
}

.spotifyLinks-title {
  letter-spacing: .05em;
  white-space: nowrap;
  text-align: center;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.8;
}

@media (min-width: 37.5em) and (max-width: 68.74em) {
  .spotifyLinks-title {
    font-size: calc(1.25rem + 1vw - 6px);
    line-height: 1.8;
  }
}

@media (min-width: 68.75em) {
  .spotifyLinks-title {
    font-size: 1.5625rem;
    line-height: 1.8;
  }
}

.spotifyLinks-subtitle {
  letter-spacing: .03em;
  white-space: nowrap;
  text-align: center;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1;
}

.spotifyLinks-button {
  max-width: 310px;
  text-align: center;
  border: 1px solid #fff;
  margin: 1.4em auto;
  padding: 14px 0 12px;
  display: block;
}

.spotifyLinks-button:hover {
  border: 1px solid #fff;
}

.spotifyLinks-button:hover .spotifyLinks-play {
  padding-left: 24px;
}

.spotifyLinks-play {
  background-image: url("play.08f44784.svg");
  background-position: 0 1px;
  background-repeat: no-repeat;
  padding-left: 34px;
  transition: all .2s;
  display: inline-block;
}

.spotifyLinks-link {
  letter-spacing: .03em;
  text-transform: none;
  text-align: center;
  margin: 0 auto 1.1em;
  font-size: .9375rem;
  font-weight: normal;
  line-height: 1;
}

.u-clearfix {
  clear: both;
}

.u-clearfix:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

@media (min-width: 0) {
  .u-pageContainer {
    width: auto;
    margin: 0 15px;
  }
}

@media (min-width: 37.5em) {
  .u-pageContainer {
    width: auto;
    margin: 0 35px;
  }
}

@media (min-width: 56.25em) {
  .u-pageContainer {
    width: auto;
    margin: 0 50px;
  }
}

@media (min-width: 68.75em) {
  .u-pageContainer {
    width: auto;
    margin: 0 70px;
  }
}

@media (min-width: 90em) {
  .u-pageContainer {
    width: 1300px;
    margin: 0 auto;
  }
}

.u-hidden-visually {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  white-space: nowrap !important;
  width: 1px !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.u-hidden {
  display: none !important;
}

html {
  overflow: auto;
}

/*# sourceMappingURL=index.581d01cd.css.map */
