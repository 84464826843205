
.album {
    width: 100%;
}

.album-cover {
    display: block;
    max-width: 640px;
    width: 70%;
    margin: 0 auto 2.4em;
    border: none;
    background-color: #000000;
    position: relative;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &:hover, &:focus, &:visited {
        border: none;
    }

    img {
        position: absolute;
        display: block;
        width: 100%;
        opacity: 0;
    }

    img.lazyloaded {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }

}

.album-links {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 2.8em;
    @include font-small;
}

.album-link {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    text-transform: none;
    text-align: center;
    margin-bottom: 1.1em;
}
