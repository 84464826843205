/* ========================================================================
   RESPONSIVE PROPERTY
   ======================================================================== */

// Use this mixin to quickly apply a map of properties to multiple breakpoints.
//
// 1. Define a map (in "settings/measurements")
//
//      $nav-margins: (
//          phone: 20px,
//          tablet: 40px,
//          desktop: 100px
//      )
//
// 2. Use it somewhere to apply the responsive properties
//
//      @include responsive-property(margin, $nav-margins);
//
// This will create the following:
//
//      .class {
//          @include mq($from: phone) { margin: 20px; }
//          @include mq($from: tablet) { margin: 40px; }
//          @include mq($from: desktop) { margin: 100px; }
//      }
//

@mixin responsive-property($property, $list) {
    @each $breakpoint-name, $value in $list {
        @include mq($from: $breakpoint-name) {
            #{$property}: $value;
        }
    }
}
