/* ==========================================================================
   BREAKPOINTS
   ========================================================================== */

/**
* Read up! http://sass-mq.github.io/sass-mq/
*
*/

$mq-breakpoints: (
    phone: 0px,
    phablet: 600px,
    tablet: 900px,
    desktop: 1100px,
    big-desktop: 1440px
);

