/* ========================================================================
   Z-Indexes
   ======================================================================== */

// Define all your z-indexes here and then use them
// with @include z-index(example)

$z-indexes: (
    modal:       1000,
    example:     900,
    background:  800,
);
