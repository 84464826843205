/* ========================================================================
   Z-INDEX
   ======================================================================== */

// Define all your z-indexes in the settings and then use them
// with @include z-index(example)

@mixin z-index($index) {
    z-index: map-get($z-indexes, $index);
}
