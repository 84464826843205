/* ========================================================================
   SIZES
   ======================================================================== */

/**
* Utilizing: https://github.com/meodai/dialog-size
*
* Usage:
* The size() function can be used in three different ways
*
* 1. Relative system
*
* size(0) will return the base size (1rem)
* size(1) will return the largest size in your system (20rem)
* size(-1) will return the smallest size in your system (0.2rem)
* size(.2) will snap to the closest value in your system
*
* 2.Names
*
* size(large) will return 1.5rem
*
* 3. Relative to Name
*
* size("smaller then large") will return 1.2rem.
*
* 4.Value
*
* There is also the possibility to use a size in the same unit than your system. (In this case REM)
*
* size(9rem) will return 10rem since it's the closest in your system
**/

// Font Sizes


$dialog-sizes-mobile:
    (15px small)
    (20px medium isBase)
    (20px large)
    (35px huge);

$dialog-sizes:
    (15px small)
    (25px medium isBase)
    (25px large)
    (80px huge);
