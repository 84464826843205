.externalLogos {
    @include fluid-type(phablet, desktop, 25px, 52px, 1);
    margin: 1em 0;
    padding: 0;
    list-style: none;
    width: 100%;
    text-align: center;
}

.externalLogos-entry {
    @include fluid-type(phablet, desktop, 18px, 26px, 1);
    width: 1em;
    height: 1em;
    display: inline-block;
    margin: 0 0.6em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.externalLogos-entry--instagram {
    background-image: url(../images/icons/instagram.svg);
    transform: scale(0.76);
}
.externalLogos-entry--facebook {
    background-image: url(../images/icons/facebook.svg);
    transform: scale(0.76);
}
.externalLogos-entry--youtube {
    background-image: url(../images/icons/youtube.svg);
    transform: scale(0.92);
}
.externalLogos-entry--soundcloud {
    background-image: url(../images/icons/soundcloud.svg);
}
.externalLogos-entry--spotify {
    background-image: url(../images/icons/spotify.svg);
    transform: scale(0.84);
}
.externalLogos-entry--applemusic {
    background-image: url(../images/icons/applemusic.svg);
    transform: scale(0.84);
}

.externalLogos-link {
    width: 100%;
    height: 100%;
    display: block;
    border: none;
    &:hover, &:focus, &:visited {
        border: none;
    }
}
