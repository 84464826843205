/* ========================================================================
   SPACINGS
   ======================================================================== */

/**
* Use this spacing settings for the @include spacing[s] mixin
*/

$space-small: (
    phone: 10px,
    tablet: 20px,
    desktop: 20px,
);

$space-medium: (
    phone: 20px,
    tablet: 40px,
    desktop: 40px,
);

$space-large: (
    phone: 40px,
    tablet: 80px,
    desktop: 100px,
);

$spacings: (
    small: $space-small,
    medium: $space-medium,
    large: $space-large,
);
