/* ==========================================================================
   VUE CLOAK
   ========================================================================== */


/**
 * https://vuejs.org/v2/api/#v-cloak
 *
 * This directive will remain on the element until the associated Vue instance finishes compilation.
 * Combined with CSS rules such as [v-cloak] { display: none }, this directive can be used to hide
 * un-compiled mustache bindings until the Vue instance is ready.
 */

[v-cloak] {
    visibility: hidden;
}
